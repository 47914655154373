(function ($) {
  var animationSpeed = 400;

  Drupal.behaviors.BasicGridFormatterV1 = {
    /**
     * jQuery object that stores all the grids after they are set up in initializeGrid
     */
    $grids: $(),

    /**
     * Sets up the basic grid for inline content by inserting many
     * js-basic-grid-formatter-inline-container div elements
     * based upon the data-grid-items-per-row attribute in the grid item (and screen size).
     * Each .js-basic-grid-formatter-inline-container div also gets specific itemId classes
     * so the proper info goes to the proper container on launch.
     * @param $grids - jQuery object of grids on the page.
     * @param size - string such as "small" or "medium" to determine the number of items in a row.
     */
    initializeGrid: function ($grids, size) {
      var self = this;

      if (typeof size === 'undefined') {
        size = Unison.fetch.now().name;
      }

      // Prep each grid (normally just one grid) by adding inline container div elements.
      $grids.has('.js-grid-item').each(function () {
        // Cache $(this) grid and remove any preexisting inline containers.
        var $thisGrid = $(this);
        var i = 0;
        var classes = '';
        var rows = [];
        var $row = $();
        var $containerDiv = $(
          '<div class="js-basic-grid-formatter-inline-container basic-grid-formatter__inline-wrapper" />'
        );
        var $items;
        var totalItems;

        self.reset($thisGrid);

        $items = $thisGrid.children('.js-grid-item:visible');
        totalItems = $items.length;

        // Loop through each item in the grid.
        $items.each(function (index) {
          var $thisItem = $(this);
          var itemId = index;
          var $cta =
            $('a.button', $thisItem).length && $('a.button', $thisItem).first().attr('href') === '#'
              ? $('a.button', $thisItem).first()
              : $();
          // Items share an inline container according to the number per row in the grid.
          var span = 1;
          var itemsPerRow =
            $thisGrid.data('gridItemsPerRow')[size.toString()] ||
            $thisGrid.data('gridItemsPerRow').medium;

          $thisItem.data('gridItemId', index);
          classes += ' js-basic-grid-formatter-inline-container--' + itemId;

          if ($(this).data('colspan')) {
            // If mobile, colspan should always be 100% regardless of data-colspan value
            span = Unison.fetch.now().name === 'small' ? itemsPerRow : $(this).data('colspan');
          }
          i += span;
          $row = $row.add($thisItem);

          /**
           * The count has either reached the end of the row, or the end of the entire grid.
           * Append the inline container!
           */
          if (i === itemsPerRow || index + 1 === totalItems) {
            $thisItem.after($containerDiv.clone()).next().addClass(classes);
            rows.push($row);
            i = 0;
            $row = $();
            classes = '';
          }

          if ($cta.length > 0) {
            $cta.addClass('basic-grid-formatter__trigger');
            $thisItem
              .find('.js-faq-toggle')
              .removeClass('collapsible-block__trigger js-faq-toggle')
              .off('click');
          } else {
            $cta = $thisItem.find('.js-faq-toggle');
          }

          $cta.off('click').on('click', function (e) {
            var $targetLink = $(e.target).closest('a');
            var $item = $(this).closest('.js-grid-item');

            if ($targetLink.length === 0 || $targetLink.attr('href') === '#') {
              e.preventDefault();
              e.stopPropagation();
              $item.trigger('basic.grid.launch.inline');
            }
          });
        });

        // Storage of completed grid and its rows.
        $thisGrid.data('item-grid-rows', rows);
        self.$grids.add($thisGrid);
      });
    },

    /* Clear the grid of all inline containers.
     * @params $grid - jQuery object of the grid to reset
     */
    reset: function ($grid) {
      // Remove all inline div container elements.
      $grid.children('.js-basic-grid-formatter-inline-container').remove();
    },

    /**
     * Standard Drupal method
     */
    attach: function (context) {
      const $grids = $('.js-basic-grid', context);

      if ($grids.length) {
        const size = Unison.fetch.now().name;

        this.initializeGrid($grids, size);
      }
    }
  };

  Unison.on('change', function (bp) {
    const $grids = $('.js-basic-grid');

    if ($grids.length) {
      Drupal.behaviors.BasicGridFormatterV1.initializeGrid($grids, bp.name);
    }
  });

  /**
   * Uses the supplied .js-grid-item to find the correct
   * .js-basic-grid-formatter-inline-container to use.
   * And then, shows the content inline.
   */
  $(document).on('basic.grid.launch.inline', '.js-grid-item', function () {
    // Store relevant element values.
    var $gridItem = $(this);
    var itemId = $gridItem.data('gridItemId');
    // Store other elements for inserting item info into inline container.
    var $grid = $gridItem.closest('.js-basic-grid');
    var $allGridItems = $('.js-grid-item', $grid);
    var $inlineContent = $('.js-faq-content', $gridItem);
    var $containers = $('.js-basic-grid-formatter-inline-container', $grid);
    // Get specific container to use for this item.
    var $container = $containers.filter('.js-basic-grid-formatter-inline-container--' + itemId);
    var $activeContainers = $containers.filter('.active');
    // Initialize some other variables
    var alreadyActive = $gridItem.hasClass('active');
    var inlineContainerTop = 0;
    var inlineContainerOffset = $('.js-site-header').height();
    var rendered = $inlineContent.html();

    // Close the content inline if it is already active (and therefore already open).
    if (alreadyActive) {
      $gridItem.trigger('basic.grid.close.inline');

      return;
    }

    // Make some items disappear before the big reveal.
    $activeContainers.hide();
    $container.empty();
    $container.append(rendered).slideDown(animationSpeed);

    // Animate the window to bring the inline container into view.
    inlineContainerTop = $container.offset().top;

    $('html, body').animate(
      {
        scrollTop:
          Unison.fetch.now().name === 'small'
            ? inlineContainerTop
            : inlineContainerTop - inlineContainerOffset - 3
      },
      {
        duration: animationSpeed
      }
    );

    $allGridItems.removeClass('active');
    $containers.removeClass('active');
    $gridItem.addClass('active');
    $container.addClass('active');
  });

  // Closes the inline content template according to the data supplied.
  $(document).on('basic.grid.close.inline', '.js-grid-item', function () {
    var $gridItem = $(this);
    var itemId = $gridItem.data('gridItemId');
    var $grid = $gridItem.closest('.js-basic-grid');
    var $container = $('.js-basic-grid-formatter-inline-container--' + itemId, $grid);

    $gridItem.removeClass('active');
    $container.slideUp(animationSpeed);
  });
})(jQuery);
